import styled from 'styled-components';
import {device} from '../../../theme'

export const BoxSectionWrap = styled.section `
    padding-top: 25px;
    padding-bottom: 50px;
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    background-color: #F8F8F8;
`;

export const BoxOuter = styled.div `
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    max-width: 70%;


    @media ${device.xxlargeOnly}{
        max-width: 1500px;
    }
    @media ${device.xlarge}{
        max-width: 80%;
    }
    @media ${device.large}{
        max-width: 80%;
    }
    @media ${device.medium}{
        max-width: 93%;
    }
`;

export const BoxWrap = styled.div `
    display: flex;
    flex-wrap: wrap;
    flex-direction: grid;
    background-color: #F8F8F8;
    justify-content: center;
`;


export const BoxCard = styled.div `
    overflow: hidden;
    box-shadow: 0 2px 20px $clr-gray300;
    display: flex;
    max-width: 330px;
    max-height: 230px;
    h4 {
        font-size: 21px;
        margin-top: 15px;
        color: #001c43;
        font-weight: 900;
        @media ${device.small}{
            font-size: 16px;
        }
    }
    p {
        font-size: 13px;
        text-align: center;
        color: #001c43;
        font-weight: 580;
        line-height: 15px;
        @media ${device.small}{
            font-size: 12px;
        }
    }
    flex-direction: column;
    background-color: white;
    border: solid #F8F8F8;
    cursor: pointer;
    margin: 10px 10px;
    padding: 2rem 1.5rem;
    align-items: center;

   
    &:hover{
            background-color: #fff;
            border: solid #A9A9A9;
        }
    }   
        img {
            background-color: #fff;
            @media ${device.small}{
                max-height: auto;
                max-width: 8%;
            }

            @media ${device.small}{
                max-height: auto;
                max-width: 7%;
            }
    }
    @media ${device.xxlargeOnly}{
        margin: 20px 20px;
        max-width: 380px;
        max-height: 280px;
    }
    
`;
