import React from 'react'
import SectionTitle from '../../../components/shared/section-title'
import {
    ClientSectionDiv,
    ClientList,
    ClientLogo,
    LogoContainer
} from './logo-clients.stc'
import Metro from '../../../data/teams/images/Metro.png'
import RailProjectsVictoria from '../../../data/teams/images/RailProjectsVictoria.png'
import CPBcontractors from '../../../data/teams/images/CPBcontractors.png'
import Yarra from '../../../data/teams/images/Yarra_Black.jpeg'
import OCG from '../../../data/teams/images/OCG.png'
import AFA from '../../../data/teams/images/AFA.png'
import MTIA2 from '../../../data/teams/images/MTIA2.png'
import AG from '../../../data/teams/images/AG.png'
import Japer from '../../../data/teams/images/Japer.png'
import MyTribe from '../../../data/teams/images/MyTribe.png'
import Subbie from '../../../data/teams/images/Subbie.png'
import Headspace from '../../../data/teams/images/Headspace.png'
import DCP from '../../../data/teams/images/DCP.png'
import LogicHealth from '../../../data/teams/images/logicHealth.png'
import ForHealth from '../../../data/teams/images/forHealth.png'
import Bounce from 'react-reveal/Bounce'

const ClientLogosSection = () => {
    return (
        <ClientSectionDiv>

            <SectionTitle
                title={'Our Clients'}
                subtitle={'Pleasure to work with'}
            />

            <ClientList>
                <LogoContainer>
                    <Bounce left>
                        <ClientLogo src={LogicHealth} alt="Atlasopen" />
                    </Bounce>
                </LogoContainer>

                <LogoContainer>
                    <Bounce left>
                        <ClientLogo src={ForHealth} alt="Atlasopen" />
                    </Bounce>
                </LogoContainer>

                <LogoContainer>
                    <Bounce left>
                        <ClientLogo src={DCP} alt="Atlasopen" />
                    </Bounce>
                </LogoContainer>

                <LogoContainer>
                    <Bounce left>
                        <ClientLogo src={Headspace} alt="Atlasopen" />
                    </Bounce>
                </LogoContainer>

                <LogoContainer>
                    <Bounce left>
                        <ClientLogo src={RailProjectsVictoria} alt="Atlasopen" />
                    </Bounce>
                </LogoContainer>

                <LogoContainer>
                    <Bounce left delay={250}>
                        <ClientLogo src={Metro} alt="Atlasopen" />
                    </Bounce>
                </LogoContainer>

                <LogoContainer>
                    <Bounce left delay={500}>
                        <ClientLogo src={CPBcontractors} alt="Atlasopen" />
                    </Bounce>
                </LogoContainer>

                <LogoContainer>
                    <Bounce left delay={750}>
                        <ClientLogo src={Yarra} alt="Atlasopen" />
                    </Bounce>
                </LogoContainer>

                <LogoContainer>
                    <Bounce left delay={750}>
                        <ClientLogo src={MTIA2} alt="Atlasopen" />
                    </Bounce>
                </LogoContainer>

                <LogoContainer>
                    <Bounce left delay={1000}>
                        <ClientLogo src={OCG} alt="Atlasopen" />
                    </Bounce>
                </LogoContainer>

                <LogoContainer>
                    <Bounce left delay={1000}>
                        <ClientLogo src={AFA} alt="Atlasopen" />
                    </Bounce>
                </LogoContainer>

                <LogoContainer>
                    <Bounce left delay={1000}>
                        <ClientLogo src={AG} alt="Atlasopen" />
                    </Bounce>
                </LogoContainer>

                <LogoContainer>
                    <Bounce left delay={1000}>
                        <ClientLogo src={Japer} alt="Atlasopen" />
                    </Bounce>
                </LogoContainer>

                <LogoContainer>
                    <Bounce left delay={1000}>
                        <ClientLogo src={MyTribe} alt="Atlasopen" />
                    </Bounce>
                </LogoContainer>

                <LogoContainer>
                    <Bounce left delay={1000}>
                        <ClientLogo src={Subbie} alt="Atlasopen" />
                    </Bounce>
                </LogoContainer>

            </ClientList>

        </ClientSectionDiv>
    )
}

export default ClientLogosSection
