import React from "react"
import SEO from '../components/seo';
import Layout from '../containers/layout/layout'
import Box from '../containers/home-slider/box'
import Hero from '../containers/home-slider/hero'
import Story from "../containers/home-slider/story";
import ProjectSection from "../containers/home-slider/project";
import TestimonialSection from "../containers/home-slider/testimonial";
import ClientSection from "../containers/home-slider/clients";
import ClientLogosSection from "../containers/home-slider/client-logos";
import BlogSection from "../containers/home-slider/blog";

export default () => (
    <Layout headerLayout={2}>
        <SEO title="Atlasopen: Data Science & Engineering, Web & Mobile App Development." description="Atlasopen is an Engineering Powerhouse. We develop Apps and bring Data Together increasing your Capabilities in App development and Data Science. From Analytics, Data Engineering, App Development, Systems Integrations to Machine Learning - We Have You Covered."/>
        <Hero/>
        <Box/>
        <ClientLogosSection/>
        <Story/>
        <ProjectSection/>
        <TestimonialSection/>
        <ClientSection/>
        <BlogSection/>
    </Layout>
)
