import React from 'react'
import { useStaticQuery, graphql, navigate } from "gatsby"
import {BoxSectionWrap, BoxWrap, BoxOuter, BoxCard} from './box.stc'
import SectionTitle from '../../../components/shared/section-title'


const Box = () => {
    const boxQueryData = useStaticQuery(graphql `
    query SliderHomeBoxDataQuery{
      homeboxdataJson(jsonId: {eq: "slider_box_section_content"}) {
        title
        cards {
          title
          subtitle
          btn_link
          icon_image {
            childImageSharp {
              fluid(
                fit: CONTAIN
                pngQuality: 100
                grayscale: true
                quality: 100
                maxHeight: 23
                maxWidth: 23
              ) {
                src
              }
            }
          }
        }
      }
    }`)

    const boxSecData = boxQueryData.homeboxdataJson
    const cardsData = boxSecData.cards
            
  return (
    <BoxSectionWrap>
      <SectionTitle title={boxSecData.title} />
        <BoxOuter>
          <BoxWrap>
              {cardsData.map((card, index) => (
                <BoxCard  onClick={() => { navigate(card.btn_link) }} key={index}>
                  <img src={card.icon_image.childImageSharp.fluid.src} className="card_image"/>
                  <h4>{card.title}</h4>
                  <p>{card.subtitle}</p>
                </BoxCard>
              ))}
          </BoxWrap>
        </BoxOuter>
    </BoxSectionWrap>
  )
}

export default Box