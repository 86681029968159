import styled from 'styled-components';
import { device } from '../../../theme'

export const ClientSectionDiv = styled.div`
    padding-top: 0px;
    padding-bottom: 100px;
    display: flex;
    // margin-bottom: 10px;
    justify-content: center;
    align-items: center;
    background-color: #F8F8F8;
    flex-direction: column;
`;

export const ClientList = styled.div`
    padding-top: 20px;
    padding-left: 50px;
    padding-right: 50px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
`;

export const ClientLogo = styled.img`
    margin-right: auto;
    margin-left: auto;
    display: block;
`;

export const LogoContainer = styled.div`
    width: 25%;
    padding: 10px;
    @media ${device.medium}{
        width: 50%;
    }
    @media ${device.small}{
        width: 100%;
    }
`;
